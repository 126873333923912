/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { completePurchaseOrder } from '../fn/purchase-order/complete-purchase-order';
import { CompletePurchaseOrder$Params } from '../fn/purchase-order/complete-purchase-order';
import { createPurchaseOrder } from '../fn/purchase-order/create-purchase-order';
import { CreatePurchaseOrder$Params } from '../fn/purchase-order/create-purchase-order';
import { getNextPurchaseOrderNumber } from '../fn/purchase-order/get-next-purchase-order-number';
import { GetNextPurchaseOrderNumber$Params } from '../fn/purchase-order/get-next-purchase-order-number';
import { getPurchaseOrder } from '../fn/purchase-order/get-purchase-order';
import { GetPurchaseOrder$Params } from '../fn/purchase-order/get-purchase-order';
import { getPurchaseOrders } from '../fn/purchase-order/get-purchase-orders';
import { GetPurchaseOrders$Params } from '../fn/purchase-order/get-purchase-orders';
import { patchPurchaseOrder } from '../fn/purchase-order/patch-purchase-order';
import { PatchPurchaseOrder$Params } from '../fn/purchase-order/patch-purchase-order';
import { patchPurchaseOrderAmountValidation } from '../fn/purchase-order/patch-purchase-order-amount-validation';
import { PatchPurchaseOrderAmountValidation$Params } from '../fn/purchase-order/patch-purchase-order-amount-validation';
import { patchPurchaseOrderGreenLights } from '../fn/purchase-order/patch-purchase-order-green-lights';
import { PatchPurchaseOrderGreenLights$Params } from '../fn/purchase-order/patch-purchase-order-green-lights';
import { PurchaseOrder } from '../models/purchase-order';
import { PurchaseOrderSearchResult } from '../models/purchase-order-search-result';
import { purchaseOrdersUuidOfferFileGet } from '../fn/purchase-order/purchase-orders-uuid-offer-file-get';
import { PurchaseOrdersUuidOfferFileGet$Params } from '../fn/purchase-order/purchase-orders-uuid-offer-file-get';
import { searchPurchaseOrders } from '../fn/purchase-order/search-purchase-orders';
import { SearchPurchaseOrders$Params } from '../fn/purchase-order/search-purchase-orders';


/**
 * Purchase order operations
 */
@Injectable({ providedIn: 'root' })
export class PurchaseOrderApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPurchaseOrders()` */
  static readonly GetPurchaseOrdersPath = '/purchase-orders';

  /**
   * Retrieve all the purchase orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPurchaseOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchaseOrders$Response(params: GetPurchaseOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrderSearchResult>> {
    return getPurchaseOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all the purchase orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPurchaseOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchaseOrders(params: GetPurchaseOrders$Params, context?: HttpContext): Observable<PurchaseOrderSearchResult> {
    return this.getPurchaseOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrderSearchResult>): PurchaseOrderSearchResult => r.body)
    );
  }

  /** Path part for operation `createPurchaseOrder()` */
  static readonly CreatePurchaseOrderPath = '/purchase-orders';

  /**
   * Create a new purchase order.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPurchaseOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPurchaseOrder$Response(params: CreatePurchaseOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrder>> {
    return createPurchaseOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new purchase order.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPurchaseOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPurchaseOrder(params: CreatePurchaseOrder$Params, context?: HttpContext): Observable<PurchaseOrder> {
    return this.createPurchaseOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrder>): PurchaseOrder => r.body)
    );
  }

  /** Path part for operation `searchPurchaseOrders()` */
  static readonly SearchPurchaseOrdersPath = '/puchase-orders/search';

  /**
   * search purchase orders based on criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPurchaseOrders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchPurchaseOrders$Response(params: SearchPurchaseOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrderSearchResult>> {
    return searchPurchaseOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * search purchase orders based on criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPurchaseOrders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchPurchaseOrders(params: SearchPurchaseOrders$Params, context?: HttpContext): Observable<PurchaseOrderSearchResult> {
    return this.searchPurchaseOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrderSearchResult>): PurchaseOrderSearchResult => r.body)
    );
  }

  /** Path part for operation `getPurchaseOrder()` */
  static readonly GetPurchaseOrderPath = '/purchase-orders/{uuid}';

  /**
   * Get purchase order.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPurchaseOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchaseOrder$Response(params: GetPurchaseOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrder>> {
    return getPurchaseOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Get purchase order.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPurchaseOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchaseOrder(params: GetPurchaseOrder$Params, context?: HttpContext): Observable<PurchaseOrder> {
    return this.getPurchaseOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrder>): PurchaseOrder => r.body)
    );
  }

  /** Path part for operation `patchPurchaseOrder()` */
  static readonly PatchPurchaseOrderPath = '/purchase-orders/{uuid}';

  /**
   * Patch a Purchase Order Entity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPurchaseOrder()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPurchaseOrder$Response(params: PatchPurchaseOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrder>> {
    return patchPurchaseOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch a Purchase Order Entity.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPurchaseOrder$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPurchaseOrder(params: PatchPurchaseOrder$Params, context?: HttpContext): Observable<PurchaseOrder> {
    return this.patchPurchaseOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrder>): PurchaseOrder => r.body)
    );
  }

  /** Path part for operation `purchaseOrdersUuidOfferFileGet()` */
  static readonly PurchaseOrdersUuidOfferFileGetPath = '/purchase-orders/{uuid}/offer-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `purchaseOrdersUuidOfferFileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  purchaseOrdersUuidOfferFileGet$Response(params: PurchaseOrdersUuidOfferFileGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return purchaseOrdersUuidOfferFileGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `purchaseOrdersUuidOfferFileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  purchaseOrdersUuidOfferFileGet(params: PurchaseOrdersUuidOfferFileGet$Params, context?: HttpContext): Observable<Blob> {
    return this.purchaseOrdersUuidOfferFileGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `completePurchaseOrder()` */
  static readonly CompletePurchaseOrderPath = '/purchase-orders/{uuid}/complete';

  /**
   * Complete the purchase order after reconciliation of the invoice.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completePurchaseOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completePurchaseOrder$Response(params: CompletePurchaseOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrder>> {
    return completePurchaseOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Complete the purchase order after reconciliation of the invoice.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completePurchaseOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completePurchaseOrder(params: CompletePurchaseOrder$Params, context?: HttpContext): Observable<PurchaseOrder> {
    return this.completePurchaseOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrder>): PurchaseOrder => r.body)
    );
  }

  /** Path part for operation `patchPurchaseOrderGreenLights()` */
  static readonly PatchPurchaseOrderGreenLightsPath = '/purchase-orders/{uuid}/green-lights';

  /**
   * Green light validation of a  Purchase Order Entity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPurchaseOrderGreenLights()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPurchaseOrderGreenLights$Response(params: PatchPurchaseOrderGreenLights$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrder>> {
    return patchPurchaseOrderGreenLights(this.http, this.rootUrl, params, context);
  }

  /**
   * Green light validation of a  Purchase Order Entity.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPurchaseOrderGreenLights$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPurchaseOrderGreenLights(params: PatchPurchaseOrderGreenLights$Params, context?: HttpContext): Observable<PurchaseOrder> {
    return this.patchPurchaseOrderGreenLights$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrder>): PurchaseOrder => r.body)
    );
  }

  /** Path part for operation `patchPurchaseOrderAmountValidation()` */
  static readonly PatchPurchaseOrderAmountValidationPath = '/purchase-orders/{uuid}/admin';

  /**
   * Admin validation of a Purchase Order Entity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPurchaseOrderAmountValidation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPurchaseOrderAmountValidation$Response(params: PatchPurchaseOrderAmountValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchaseOrder>> {
    return patchPurchaseOrderAmountValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * Admin validation of a Purchase Order Entity.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPurchaseOrderAmountValidation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPurchaseOrderAmountValidation(params: PatchPurchaseOrderAmountValidation$Params, context?: HttpContext): Observable<PurchaseOrder> {
    return this.patchPurchaseOrderAmountValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchaseOrder>): PurchaseOrder => r.body)
    );
  }

  /** Path part for operation `getNextPurchaseOrderNumber()` */
  static readonly GetNextPurchaseOrderNumberPath = '/purchase-orders/next-order-number';

  /**
   * get the next purchase order number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNextPurchaseOrderNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextPurchaseOrderNumber$Response(params?: GetNextPurchaseOrderNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getNextPurchaseOrderNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * get the next purchase order number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNextPurchaseOrderNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextPurchaseOrderNumber(params?: GetNextPurchaseOrderNumber$Params, context?: HttpContext): Observable<string> {
    return this.getNextPurchaseOrderNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
